<template>
  <div>
    <title-nav :title="'리뷰관리'" :nav="'CS / 리뷰관리'" />
    <b-container fluid>
      <div class="card">
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <!-- 리뷰 등록일시  -->
            <template v-slot:cell(reg_dt)="data">
              <span>{{ data.value | dateFilter }}</span>
            </template>

            <template v-slot:cell(point)="data">{{ data.value | commaFilter }}</template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <h4>데이터가 없습니다.</h4>
            </template>
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="curPage"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { getReview } from '@/api/cs'

export default {
  name: 'Review',
  data() {
    return {
      curPage: 1,
      rowPerPage: 20,
      records: 0,
      isBusy: false,

      fields: [
        {
          key: 'review_sn',
          label: 'NO.',
          colType: 'span'
        },
        {
          key: 'user_sn',
          label: '고객 번호',
          colType: 'span'
        },
        {
          key: 'email',
          label: 'email',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'star',
          label: '별점',
          colType: 'span'
        },
        {
          key: 'contents',
          label: '작성글',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'reg_dt',
          label: '등록일',
          colType: 'span'
        }
      ],
      items: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark'
    }
  },
  watch: {
    rowPerPage: function(newValue) {
      console.log(newValue)
      this.init()
    }
  },
  methods: {
    async init() {
      this.isBusy = true
      const resp = await getReview(this.curPage, this.rowPerPage)
      if (resp.code === '0000') {
        this.items = resp.data.list
      }
      this.records = resp.data.totCnt
      this.isBusy = false
    },
    setPage(page) {
      this.curPage = page
      this.init()
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped>
table tbody tr .td-title {
  color: #fff;
  background-color: #212529;
  border-color: #383f45;
  padding-left: 10px;
}

table tbody tr .td-data {
  color: #000;
  background-color: #ffffff;
  border-color: #383f45;
  text-align: left;
  padding-left: 10px;
}
</style>
